import { Box, HStack, Button } from "@chakra-ui/react";
import ColorInput from "@features/ui/ColorInput";
import { themes } from "./Hero.helpers";

interface ThemeControlProps {
    theme: string;
    onChange: (value: string) => void;
}

function ThemeControl(props: ThemeControlProps){
    return (
        <Box
            position="absolute"
            rounded="full"
            p="2"
            right="2%"
            top="-15%"
            zIndex={2}   
            bg="white"
            boxShadow="15px 15px 50px 10px rgb(0, 0, 0, 0.08)"
        >
            <HStack spacing="2">
                {
                    themes.map(theme => {
                        return (
                            <Button 
                                key={theme}
                                variant="unstyled"
                                bg={theme}
                                rounded="full"
                                width="16px"
                                minW="16px"
                                height="16px"
                                aria-selected={theme === props.theme}
                                onClick={() => props.onChange(theme)}
                                _focus={{
                                    boxShadow: "outline"
                                }}
                                _selected={{
                                    boxShadow: "outline"
                                }}
                            />
                        )
                    })
                }
            </HStack>
        </Box>
    )
}

export default ThemeControl;
import { Box, Flex } from "@chakra-ui/react";
import { useResponsiveLayout } from "./Hero.helpers";
import { AnimatePresence, motion } from "framer-motion";

const FORM_ITEMS = ["Name", "Email", "Phone", "How Can I Help you?"]

interface FormDemoProps {
    theme: string;
}

function FormDemo(props: FormDemoProps){
    const { ref, width, responsive } = useResponsiveLayout(500);

    return (
        <Box 
            ref={ref}
            position="absolute" 
            width="calc(100% / 3)"
            top="-20%"
            left={{ base: "-5%", md: "-15%" }}
        >
            <AnimatePresence>
                {
                    width && 
                    <motion.div
                        initial={{ opacity: 0, x: 40 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3 }}
                    >
                        <Box 
                            p={responsive(32)}
                            boxShadow="15px 15px 50px 10px rgb(0, 0, 0, 0.05)"
                            bg="white"
                            rounded="lg"
                        >
                            <Box 
                                fontWeight={700}
                                mb={responsive(24)}
                                fontSize={responsive(32)}
                            >
                                Hire Me!
                            </Box>
                            {
                                FORM_ITEMS.map(item => {
                                    return (
                                        <Box key={item} mb={responsive(16)}>
                                            <Box mb={responsive(8)} fontSize={responsive(16)}>{item}</Box>
                                            <Box
                                                height={responsive(40)}
                                                borderRadius={responsive(6)}
                                                border="1px"
                                                borderColor="gray.200"
                                            />
                                        </Box> 
                                    )
                                })
                            }

                            <Flex
                                color="white"
                                height={responsive(40)}
                                bg={props.theme}
                                borderRadius={responsive(6)}
                                align="center"
                                justify="center"
                                fontSize={responsive(16)}
                            >
                                Submit
                            </Flex>
                        </Box>
                    </motion.div>
                  
                }
            </AnimatePresence>
        </Box>
    )
}

export default FormDemo;
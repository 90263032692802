import React from "react";
import useResizeObserver from "use-resize-observer";
import { SITE_LINKS } from "@features/sites/constants";

export const DEMO_SOCIAL_LINKS = SITE_LINKS.slice(0, 5);

export const DEMO_NAV_LINKS = ["home", "about", "contact"];

export function useResponsiveLayout(initialWidth = 1280){
    const ref = React.useRef<HTMLDivElement |  null>(null);
    const { width } = useResizeObserver({ ref });

    return {
        ref,
        width,
        responsive: (value: number) => {
            if(!width) return 0;
            return width * value / initialWidth + "px";
        }
    }
}

export const themes = [
    "#111111",
    "#1336EE",
    "#319795",
]
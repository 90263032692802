import StandardLayout from "@features/ui/StandardLayout";
import type { NextPage } from "next";
import { Box } from "@chakra-ui/react";
import Hero from "@features/ui/HomePage/Hero";
import Features from "@features/ui/HomePage/Features";
import AboutFeatures from "@features/ui/HomePage/AboutFeatures";
import FAQ from "@features/ui/HomePage/FAQ";

const Home: NextPage = () => {
    return (
        <StandardLayout>
            <Box overflowX="hidden">
                <Box>
                    <Hero />
                </Box>
                <Box>
                    <Features />
                </Box>
                <Box overflowX="hidden">
                    <AboutFeatures />
                </Box>
                <Box>
                    <FAQ />
                </Box>
            </Box>
        </StandardLayout>
    );
};

export default Home;

import React from "react";
import { SiteLink, Section, HeroSectionProps } from "./types";
import {
    FaFacebookSquare as FacebookIcon,
    FaSpotify as SpotifyIcon,
    FaBehanceSquare as BehanceIcon,
    FaProductHunt as ProductHuntIcon,
    FaTelegramPlane as TelegramIcon,
    FaSnapchat as SnapchatIcon
} from "react-icons/fa";
import {
    IoLogoWhatsapp as WhatsappIcon,
    IoMdMail as MailIcon,
} from "react-icons/io";
import { AiFillInstagram as InstagramIcon } from "react-icons/ai";
import {
    BsTwitter as TwitterIcon,
    BsLinkedin as LinkedinIcon,
    BsYoutube as YoutubeIcon,
} from "react-icons/bs";
import { SiTiktok as TiktokIcon } from "react-icons/si";
import { SiGooglemaps as GoogleMaps } from "react-icons/si";
import { SocialLinks } from "social-links";
import { isValidEmail, isValidSpotify } from "@lib/utils";

const socialLinks = new SocialLinks();

interface SectionTypeOption {
    label: string;
    value: Section["section_type"];
}

interface ColorControl {
    label: string;
    name: keyof ThemeProps;
}

export const SITE_LINKS: SiteLink[] = [
    {
        name: "facebook_url",
        label: "Facebook",
        icon: <FacebookIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid("facebook", link) &&
                socialLinks.detectProfile(link) === "facebook"
            );
        },
    },
    {
        name: "whatsapp_url",
        label: "Whatsapp Number",
        icon: <WhatsappIcon />,
        getHref: (number: string) => `https://wa.me/${number}`,
        hint: `
            Omit any zeroes, brackets, or dashes when adding the phone number in international format. <br />
            Use: 1XXXXXXXXXX <br />
            Don't use: +001-(XXX)XXXXXXX
        `,
    },
    {
        name: "instagram_url",
        label: "Instagram",
        icon: <InstagramIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid("instagram", link) &&
                socialLinks.detectProfile(link) === "instagram"
            );
        },
    },
    {
        name: "twitter_url",
        label: "Twitter",
        icon: <TwitterIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid("twitter", link) &&
                socialLinks.detectProfile(link) === "twitter"
            );
        },
    },
    {
        name: "linkedin_url",
        label: "Linkedin",
        icon: <LinkedinIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid(
                    "linkedin",
                    link?.replace("company", "in")
                ) &&
                socialLinks.detectProfile(link.replace("company", "in")) ===
                    "linkedin"
            );
        },
    },
    {
        name: "tiktok_url",
        label: "Tiktok",
        icon: <TiktokIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid("tiktok", link) &&
                socialLinks.detectProfile(link) === "tiktok"
            );
        },
    },
    {
        name: "email",
        label: "Email",
        icon: <MailIcon />,
        type: "email",
        validate: isValidEmail,
        getHref: (email: string) => `mailto:${email}`,
    },
    {
        name: "google_maps_url",
        label: "Google Maps",
        icon: <GoogleMaps />,
    },
    {
        name: "spotify_url",
        label: "Spotify",
        icon: <SpotifyIcon />,
        validate: isValidSpotify,
    },
    {
        name: "youtube_url",
        label: "Youtube",
        icon: <YoutubeIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid("youtube", link) &&
                socialLinks.detectProfile(link) === "youtube"
            );
        },
    },
    {
        name: "behance_url",
        label: "Behance",
        icon: <BehanceIcon />,
        validate: (link: string) => {
            return (
                socialLinks.isValid("behance", link) &&
                socialLinks.detectProfile(link) === "behance"
            );
        },
    },
    {
        name: "telegram_url",
        label: "Telegram",
        icon: <TelegramIcon />,
    },
    {
        name: "product_hunt_url",
        label: "Product Hunt",
        icon: <ProductHuntIcon />,
    },
    {
        name: "snapchat_url",
        label: "Snapchat",
        icon: <SnapchatIcon />,
    }
];

export const SECTION_TYPES_OPTIONS: SectionTypeOption[] = [
    {
        label: "Text",
        value: "text_only",
    },
    {
        label: "Video",
        value: "video",
    },
    {
        label: "Flat Carousel",
        value: "flat_carousel",
    },
    {
        label: "Stacked Carousel",
        value: "stacked_carousel",
    },
    {
        label: "Form",
        value: "form",
    },
    {
        label: "Iframe",
        value: "iframe",
    },
    {
        label: "Single Photo",
        value: "single_photo",
    },
    {
        label: "Photo Grid",
        value: "photo_grid",
    },
];

import { ThemeProps } from "@features/sites/types";
import { isValid } from "date-fns/esm";

export const COLOR_CONTROLS: ColorControl[] = [
    {
        name: "theme_main_color",
        label: "Primary Color",
    },
    {
        name: "theme_secondary_color",
        label: "Secondary Color",
    },
    {
        name: "font_main_color",
        label: "Text Primary Color",
    },
    {
        name: "font_secondary_color",
        label: "Text Secondary Color",
    },
];

export const COLOR_OPTIONS = [
    "#001122",
    "#715DF2",
    "#6600FF",
    "#0066FF",
    "#4FACF7",
    "#009473",
    "#F7CACA",
    "#FCAF3C",
    "#F7770F",
    "#FF6F61",
    "#C62368",
    "#A8A89E",
    "#FF0066",
    "#CCCCCC",
    "#FFFFFF",
];

export const HERO_SECTION_PROPS: (keyof HeroSectionProps)[] = [
    "theme_main_color",
    "theme_secondary_color",
    "font_main_color",
    "font_secondary_color",
    "hero_section_body",
    "hero_section_title",
    "hero_section_logo",
    "hero_section_photo",
    "name",
];

export const SETTINGS_PROPS = ["subdomain_name", "text_alignment"];

export const SITE_LINKS_PROPS = SITE_LINKS.map((link) => link.name);

import React from "react";
import MaxWidthWrapper from "@features/ui/MaxWidthWrapper";
import SiteDemo from "./SiteDemo";
import { Box, Heading, Flex, Button } from "@chakra-ui/react";
import FormDemo from "./FormDemo";
import GridSectionDemo from "./GridSectionDemo";
import ThemeControl from "./ThemeControl";
import { themes } from "./Hero.helpers";
import { useScroll, motion, useTransform } from "framer-motion";
import { HiArrowRight as RightArrow } from "react-icons/hi";
import Link from "next/link";

function Hero() {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [theme, setTheme] = React.useState(themes[0]);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["-100px", "+80vh"],
    });
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0.5]);
    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.7]);
    const y = useTransform(scrollYProgress, [0, 1], [0, 200]);

    return (
        <MaxWidthWrapper pt="100px" pb="60px" mb="10">
            <Flex
                direction="column"
                align="center"
                justify="center"
                mb="120px"
                maxW="768px"
                mx="auto"
                color="gray.700"
            >
                <Heading
                    size={{ base: "2xl", sm: "3xl" }}
                    textAlign="center"
                    textTransform="capitalize"
                    lineHeight="1.4"
                    mb="12"
                >
                    the simplest way to build a landing page
                </Heading>

                <Link href="/landing-pages/new" passHref>
                    <Button
                        mb="6"
                        size={{ base: "md", md: "lg" }}
                        colorScheme="brand"
                        rightIcon={<RightArrow />}
                        as="a"
                    >
                        Create Your Landing Page For Free
                    </Button>
                </Link>

                {/* <a
                    href="https://www.producthunt.com/posts/bithub?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-bithub"
                    target="_blank"
                >
                    <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=379572&theme=neutral&period=daily"
                        alt="BITHUB - Simplest&#0032;way&#0032;to&#0032;build&#0032;a&#0032;landing&#0032;page | Product Hunt"
                        style={{
                            width: "250px",
                            height: "54px",
                        }}
                        width="250"
                        height="54"
                    />
                </a> */}

                <a
                    href="https://www.producthunt.com/posts/bithub?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-bithub"
                    target="_blank"
                >
                    <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=379572&theme=light&period=monthly&topic_id=164"
                        alt="BITHUB - Simplest&#0032;way&#0032;to&#0032;build&#0032;a&#0032;landing&#0032;page | Product Hunt"
                        style={{
                            width: "250px",
                            height: "54px",
                        }}
                        width="250"
                        height="54"
                    />
                </a>
            </Flex>

            <motion.div style={{ y, scale, opacity }}>
                <Box maxW="768px" mx="auto" position="relative" ref={ref}>
                    <SiteDemo theme={theme} />

                    <FormDemo theme={theme} />

                    <GridSectionDemo theme={theme} />

                    <ThemeControl theme={theme} onChange={setTheme} />
                </Box>
            </motion.div>
        </MaxWidthWrapper>
    );
}

export default Hero;

import { AiFillThunderbolt as ThunderIcon } from "react-icons/ai";
import { MdScreenSearchDesktop as SearchIcon, MdOutlineInsights as InsightsIcon } from "react-icons/md"
import { IoShareSocial as ShareIcon } from "react-icons/io5"
import { BsFillPatchCheckFill as ValidateIcon } from "react-icons/bs"
import { RiSurveyLine as FormIcon } from "react-icons/ri";

export const features = [
    {
        title: "Blazing Fast Performance",
        description: "Focus on your customer needs and conversions and let us handle your Landing Page performance using our cutting-edge technology.",
        icon: <ThunderIcon />,
        gradient: "linear-gradient(to right, #ff416c, #ff4b2b)"
    },
    {
        title: "Search Engine Friendly",
        description: "Let our SEO Algorithm rank your Landing Page on top of search results and bring you more leads/sales.",
        icon: <SearchIcon />,
        gradient: "linear-gradient(to right, #2193b0, #6dd5ed)"
    },
    {
        title: "Tracking Visitors Insights",
        description: "See how many views your link in the bio page has received and understand the performance of each link.",
        icon: <InsightsIcon  />,
        gradient: "linear-gradient(to right, #bc4e9c, #f80759)"
    },
    {
        title: "All-in-One Link",
        description: "Highlight your most important links, articles, photos, videos, products, music, website. Grow your following across every platform by linking them all in one place.",
        icon: <ShareIcon />,
        gradient: "linear-gradient(to right, #6a3093, #a044ff)"
    },
    {
        title: "New Idea Validation",
        description: "Test out your products, messaging, and ideas before you launch, so you can find your future customers, optimize your conversion rates, and get people excited about your brand.",
        icon: <ValidateIcon />,
        gradient: "linear-gradient(to right, #11998e, #38ef7d)"
    },
    {
        title: "Lead Generation Forms",
        description: "Create customisable forms to generate leads for your business or brand promotion.",
        icon: <FormIcon />,
        gradient: "linear-gradient(to right, #11998e, #38ef7d)"
    }
];

export default features;
import React from "react";
import MaxWidthWrapper from "@features/ui/MaxWidthWrapper";
import { Box, Heading, Grid, GridItem, Text, Center } from "@chakra-ui/react";
import { features } from "./Features.constants";
import { motion } from "framer-motion";

function Features(){
    return (
        <Box bg="gray.50" py="100px" as="section" mt="150px">
            <MaxWidthWrapper>
                <Box textAlign="center" mb="20">
                    <Heading 
                        size="2xl" 
                        textTransform="capitalize"
                        maxW="800px"
                        mx="auto"
                        lineHeight="1.2"
                    >
                        launch your landing page in few minutes
                    </Heading>
                </Box>
                
                <Grid
                    templateColumns={{ base: "1fr", md: "repeat(auto-fit, minmax(300px, 1fr))" }}
                    gap="6"
                >
                    {
                        features.map((feature, index) => {
                            return (
                                <GridItem 
                                    position="relative" 
                                    display="flex"
                                    key={index}
                                    as={motion.div}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true, margin: "-150px" }}
                                >
                                    <Box
                                        width="100%"
                                        position="relative"
                                        zIndex="1"
                                        p="4"
                                    >
                                        <Center
                                            width="14"
                                            height="14"
                                            rounded="full"
                                            mb="4"
                                            bg="brand.100"
                                            color="brand.600"
                                            fontSize="32px"
                                        >
                                            {feature.icon}
                                        </Center>

                                        <Text 
                                            as="h5" 
                                            fontSize="2xl" 
                                            fontWeight="bold" 
                                            mb="3" 
                                            maxW="500px"
                                        >
                                            {feature.title}
                                        </Text>

                                        <Text fontSize="md">
                                            {feature.description}
                                        </Text>
                                    </Box>
                                </GridItem>
                            )
                        })
                    }
                </Grid>
            </MaxWidthWrapper>
        </Box>
    )
}

export default Features;
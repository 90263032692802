import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";

export const FAQ = [
    {
        title: "What does landing page mean?",
        answer: (
            <Text>
                A Landing page is standalone web page that can help you turn
                prospects into customers. In general, these types of pages
                aren’t included in your website’s navigation. Instead, they’re
                accessible via links from ads, newsletters, link in bio, and so
                forth.
            </Text>
        ),
    },
    {
        title: "How does a Landing Page work?",
        answer: (
            <Box>
                <Text mb="2">
                    A Landing Page is a versatile free microsite that you can
                    use to share your most valuable content. It can help many
                    different types of organizations and brands share their
                    content faster:
                </Text>

                <UnorderedList pl="3">
                    {[
                        "Are you thinking of building a website but don’t have the time or budget for it currently? A landing page is a great way to get your content out into the world without spending too much time or money!",
                        "Are you are an individual building your personal brand? A landing page is the ideal way to share everything about yourself in a quick and sleek way.",
                        "Have you been looking for a space to share links to various places online where your audience can find you? You can do that with a landing page!",
                        "Are you planning an event? You can use a landing page to share all the details.",
                    ].map((item, index) => {
                        return (
                            <ListItem key={index} mb="1">
                                {item}
                            </ListItem>
                        );
                    })}
                </UnorderedList>
            </Box>
        ),
    },
    {
        title: "How can I use my Landing Page?",
        answer: (
            <Text>
                Your landing page should include the most relevant information
                about your products, services, blogs, videos, events, updates,
                and social profiles. For example, you could use your landing
                page to streamline all your contact information, promote your
                music by embedding your Spotify songs, or house updates about
                your services and product.{" "}
            </Text>
        ),
    },
    {
        title: "Can I use a landing page instead of a website?",
        answer: (
            <Text>
                Marketers often use landing pages to help users “land” on
                specific web pages linked on an email, social media post, or ad.
                While they may not be an adequate replacement for a website,
                landing pages are valuable to reach short-term marketing
                objectives. For example, you can allow users to order pre-sale
                items or sign-up for limited edition products with a landing
                page.{" "}
            </Text>
        ),
    },
    {
        title: "What does link in bio mean?",
        answer: (
            <Text>
                Since most social platforms don’t allow users to put links in
                their social media posts, the only place where you can put a
                link is in your social profile’s description area, which is also
                called the “bio.” When people say “link in bio” they are
                referring to the clickable link that you can find in the social
                media profile bio of Instagram, TikTok, or even Twitter. The
                link usually takes followers to a a website, a specific article,
                a product page, a landing page, or any other important URL.{" "}
            </Text>
        ),
    },
    {
        title: "Why should I use a link in bio on Instagram?",
        answer: (
            <Text>
                On Instagram, you only get one link on your profile page, and
                it’s important to optimize it. A{" "}
                <Box
                    as="a"
                    href="https://blog.parse.ly/link-in-bio-providers-add-10-15-to-instagram-referral-traffic/"
                >
                    Parse.ly
                </Box>{" "}
                study found that adding a link in bio to your Instagram profile
                can drive an additional 10-15 percent Instagram referral traffic
                to your landing page. Using a link in bio can help you drive
                more traffic from your social profiles to your most valuable
                content and grow your audience across your social profiles.
            </Text>
        ),
    },
    {
        title: "Who can use a Landing Page?",
        answer: (
            <Box>
                <Text>
                    A landing page can be used by everyone, including small
                    businesses, influencers, nonprofits or charities, musicians,
                    event planners, or anyone building a personal brand.
                </Text>

                <Text>
                    BITHub allows you to share your content with the world in a
                    simple and beautiful way, and the possibilities are endless!
                </Text>
            </Box>
        ),
    },
    {
        title: "Can I use my own domain?",
        answer: (
            <Text>
                This feature is coming soon. If you already own a domain you can
                re-direct it to your landing page. Using a masked redirect
                should do the trick.
            </Text>
        ),
    },
];

import React from "react";
import { Avatar, Box, Flex } from "@chakra-ui/react";
import Image from "next/image";
import { motion } from "framer-motion";

interface ReplyProps {
    messages: React.ReactNode[];
    userImage?: string;
    byCurrentUser?: boolean;
}


function Reply(props: ReplyProps){
    return (
        <Flex 
            align="flex-end" 
            justify={props.byCurrentUser ? "flex-end" : "flex-start"}
            mb="2"
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
        >
            {
                !props.byCurrentUser && 
                <Box mr="2">
                    <Avatar size="sm" src={props.userImage} />
                </Box>    
            }

            <Flex
                direction="column"
                align={props.byCurrentUser ? "flex-end" : "flex-start"}
                flexGrow="1"
                maxW="70%"
                sx={{
                    ".reply-message:last-child": {
                        mb: "0"
                    }
                }}
            >
                {
                    props.messages.map((message, index) => {
                        if(typeof message !== "string") return <div key={index}>{message}</div>;

                        return (
                            <Box 
                                className="reply-message"
                                key={index}
                                py="2"
                                px="3"
                                bg={props.byCurrentUser ? "brand.500" : "gray.100"}
                                color={props.byCurrentUser ? "white" : "gray.700"}
                                mb="1"
                                fontSize="sm"
                                rounded="full"
                            >
                                {message}
                            </Box>  
                        )
                    })
                }
            </Flex>
        </Flex>
    )
}

function FakeMessengerChat(){
    return (
        <Box>
            <Box>
                <Reply 
                    userImage="/images/demo-client-image.jpg"
                    messages={[
                        "Hi Amy!",
                        "Do you have an instagram page?",
                        "Can you share your contact info, I would like to hire you"
                    ]}
                />   

                <Reply 
                    byCurrentUser
                    messages={[
                        // "Hi, yea sure!",
                        "You can find my contact info, online pages, and work here",
                        <Box
                            mb="2"
                            overflow="hidden"
                            rounded="lg"
                            maxW="190px"
                        >
                            <Box p="2" bg="brand.500" color="white" fontSize="sm">https://amyalison.the-bit..</Box>
                            <Image 
                                src="/images/amyalison.jpg"
                                width={200}
                                height={100}
                            />
                            <Box p="2" bg="gray.100" mt="-2" fontSize="sm">
                                <Box fontWeight="semibold">Hello, I am Amy Alison</Box>
                                <Box fontSize="xs" color="gray.500">
                                    I am a professional photographer... 
                                </Box>
                            </Box>
                        </Box>,
                        "Just send me a hire request via my website."
                    ]}
                />

                <Reply 
                    messages={["Cool! that was so simple!"]}
                    userImage="/images/demo-client-image.jpg"
                />
            </Box>
        </Box>
    )
}

export default FakeMessengerChat;
import { Box, AspectRatio, Grid, GridItem, Center } from "@chakra-ui/react";
import Image from "next/image";
import { useResponsiveLayout } from "./Hero.helpers";
import { BsPlusCircleDotted as PlusIcon } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";

interface GridSectionDemoProps {
    theme: string;
}

const images = [
    "/images/grid-section-demo-img1.jpg",
    null,
    "/images/grid-section-demo-img3.jpg",
    null,
    "/images/grid-section-demo-img2.jpg",
    null
];

function GridSectionDemo(props: GridSectionDemoProps){
    const { ref, width, responsive } = useResponsiveLayout(1024);

    return (
        <Box
            ref={ref}
            position="absolute"
            width="50%"
            right={{ base: "-5%", md: "-20%" }}
            bottom="-15%"
            zIndex={2}
        >
            <AnimatePresence>    
                {
                    width && 
                    <motion.div
                        initial={{ opacity: 0, y: 40 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                    >
                        <Grid
                            p={responsive(24)}
                            templateColumns="repeat(3, 1fr)"
                            gap={responsive(16)}      
                            boxShadow="15px 15px 50px 10px rgb(0, 0, 0, 0.08)"
                            bg="white"
                            rounded="lg"                      
                        >
                            {
                                images.map((image, index) => {
                                    return (
                                        <GridItem key={index}>
                                            <AspectRatio ratio={3 / 2}>
                                                {
                                                    image ? 
                                                    <Image  
                                                        src={image}
                                                        layout="fill"
                                                        style={{ borderRadius: responsive(8) }}
                                                    /> :
                                                    <Center 
                                                        borderRadius={responsive(8)}
                                                        bg="gray.200"
                                                        fontSize={responsive(48)}
                                                        color={props.theme}
                                                    >
                                                        <PlusIcon />
                                                    </Center>                                    
                                                }
                                            </AspectRatio>
                                        </GridItem>
                                    )
                                })
                            }
                        </Grid>
                    </motion.div>
                }
            </AnimatePresence>
        </Box>
    )
}

export default GridSectionDemo;
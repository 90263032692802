import {
    Heading,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon
} from "@chakra-ui/react";
import MaxWidthWrapper from "@features/ui/MaxWidthWrapper";
import { FAQ as questions } from "./FAQ.constants";

function FAQ() {
    return (
        <Box as="section" py="50px">
            <MaxWidthWrapper>
                <Heading size="2xl" mb="16">FAQs</Heading>
                <Box>
                    <Accordion allowMultiple>
                        {questions.map((question, index) => {
                            return (
                                <AccordionItem key={index}>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left" fontWeight="medium" py="2">
                                                {question.title}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        {question.answer}
                                    </AccordionPanel>
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </Box>
            </MaxWidthWrapper>
        </Box>
    );
}

export default FAQ;

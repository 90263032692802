import React from "react";
import { AspectRatio, Box, Flex, Grid, GridItem, Heading, HStack, Text } from "@chakra-ui/react";
import Image from "next/image";
import { DEMO_NAV_LINKS, DEMO_SOCIAL_LINKS, useResponsiveLayout } from "./Hero.helpers";
import Color from "color";
import { AnimatePresence, motion } from "framer-motion";

interface SiteDemoProps {
    theme: string;
}

function SiteDemo(props: SiteDemoProps){
    const { ref, responsive, width } = useResponsiveLayout();

    return (
        <Box 
            ref={ref}
            position="relative"
            zIndex={1}
        >
            <AnimatePresence>
                {
                    width &&
                    <Box 
                        as={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        p={responsive(48)}
                        rounded="lg" 
                        mx="auto"
                        bg="white"
                        boxShadow="15px 15px 50px 10px rgb(0, 0, 0, 0.05)"
                        color="gray.700"
                    >
                        {/* Header */}
                        <Flex align="center" justify="space-between" mb={responsive(64)}>
                            <Box>
                                <Box maxW={responsive(120)}>
                                    <Image 
                                        src="/images/demo-logo.svg"
                                        width="86"
                                        height="22"
                                    />
                                </Box>
                            </Box>

                            <HStack align="center" spacing="4">
                                {
                                    DEMO_NAV_LINKS.map((link, index) => {
                                        return (
                                            <Box 
                                                key={index} 
                                                textTransform="capitalize" 
                                                fontWeight="semibold" 
                                                fontSize={responsive(18)}
                                            >
                                                {link}
                                            </Box>
                                        )
                                    })
                                }
                            </HStack>
                        </Flex>

                        {/* Hero */}
                        <Grid templateColumns="1fr 1fr" gap="8" alignItems="center" mb={responsive(48)}>
                            <GridItem>
                                <Heading fontSize={responsive(48)} mb={responsive(32)}>
                                    Hello, I am Amy Alison
                                </Heading>
                                <Text fontSize={responsive(18)} mb={responsive(32)}>
                                    I am a professional photographer as well as a creative graphic designer.
                                    I work with many companies and offer quality work at the best price. 
                                    Not only that, but I provide the highest quality services and my purpose 
                                    is to provide quality graphic design services that you will need 
                                    to complete your projects.
                                </Text>
                                <HStack spacing={responsive(16)}>
                                    {
                                        DEMO_SOCIAL_LINKS.map(link => {
                                            return (
                                                <Flex 
                                                    align="center" 
                                                    justify="center" 
                                                    width={responsive(50)}
                                                    height={responsive(50)}
                                                    rounded="full"
                                                    bg={Color(props.theme).alpha(0.1).toString()}
                                                    fontSize={responsive(30)}
                                                    key={link.name}
                                                >
                                                    {link.icon}
                                                </Flex>
                                            )
                                        })
                                    }
                                </HStack>
                            </GridItem>

                            <GridItem display="flex" justifyContent="center">
                                <Box position="relative">
                                    <Image 
                                        src="/images/demo-image.jpg" 
                                        width={594} 
                                        height={391} 
                                        style={{ zIndex: 1, borderRadius: responsive(16) }} 
                                    />
                                    <Box 
                                        position="absolute"
                                        top={responsive(12)}
                                        left={responsive(12)}
                                        width="100%"
                                        height="100%"
                                        borderRadius={responsive(16)}
                                        bg={Color(props.theme).alpha(0.3).toString()}
                                        border="2px"
                                        borderColor={props.theme}
                                    />
                                </Box>
                            </GridItem>
                        </Grid>
                    </Box>
                }
            </AnimatePresence>
          
        </Box>
    )
}

export default SiteDemo;
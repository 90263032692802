import {
    Box,
    GridItem,
    Grid,
    Heading,
    Text,
    Button,
    Wrap,
    chakra,
    HStack,
    WrapItem,
} from "@chakra-ui/react";
import Counter from "@features/ui/Counter";
import MaxWidthWrapper from "@features/ui/MaxWidthWrapper";
import Image from "next/image";
import Link from "next/link";
import FakeMessengerChat from "./FakeMessengerChat";
import FakeNotifications from "./FakeNotifications";
import {
    AiFillApple as AppleIcon,
    AiFillAndroid as AndroidIcon,
} from "react-icons/ai";

const InsightsCard = chakra("div", {
    baseStyle: {
        textAlign: "center",
        rounded: "lg",
        bg: "white",
        boxShadow: "5px 5px 15px rgb(0, 0, 0, 0.08)",
        position: "absolute",
        p: "2",
        minW: "200px",
    },
});

function AboutFeatures() {
    return (
        <Box as="section" position="relative" overflowX="hidden">
            <MaxWidthWrapper>
                <Grid
                    templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                    alignItems="center"
                    gap="8"
                    py="60px"
                >
                    <GridItem>
                        <Heading
                            size="xl"
                            textTransform="capitalize"
                            lineHeight="1.4"
                            mb="5"
                        >
                            sharing your online pages, contacts or location has
                            never been easier
                        </Heading>

                        <Text mb="8">
                            Highlight your most important links, articles,
                            photos, videos, products, music, website. Share your
                            Landing Page wherever your audience is: Instagram,
                            YouTube, TikTok, WhatsApp, in messengers, via SMS –
                            wherever you’re online.
                        </Text>

                        <Link href="/register" passHref>
                            <Button as="a" colorScheme="brand" size="lg">
                                Get Started
                            </Button>
                        </Link>
                    </GridItem>

                    <GridItem>
                        <FakeMessengerChat />
                    </GridItem>
                </Grid>

                <Grid
                    templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                    gap="16"
                    py="60px"
                    alignItems="center"
                >
                    <GridItem order={{ base: "1", lg: "0" }} textAlign="center">
                        <Box position="relative" display="inline-block">
                            <Box
                                p="3"
                                rounded="lg"
                                boxShadow="15px 15px 50px rgb(0, 0, 0, 0.05)"
                                maxW="500px"
                                mx="auto"
                            >
                                <Image
                                    src="/images/demo-form.png"
                                    width={1120}
                                    height={207}
                                />
                            </Box>

                            <InsightsCard left="-15%" bottom="-40%">
                                <Box color="brand.500" fontWeight="bold" mb="1">
                                    Unique Visitors
                                </Box>

                                <Box
                                    fontWeight="extrabold"
                                    fontSize="large"
                                    color="gray.600"
                                >
                                    <Counter to={4732} />
                                </Box>
                            </InsightsCard>

                            <InsightsCard right="-15%" top="-40%">
                                <Box color="brand.500" fontWeight="bold" mb="1">
                                    Total Visits
                                </Box>

                                <Box
                                    fontWeight="extrabold"
                                    fontSize="large"
                                    color="gray.600"
                                >
                                    <Counter to={6876} duration={2} />
                                </Box>
                            </InsightsCard>
                        </Box>
                    </GridItem>

                    <GridItem order={{ base: "0", lg: "1" }}>
                        <Heading
                            size="xl"
                            textTransform="capitalize"
                            lineHeight="1.4"
                            mb="5"
                        >
                            track your landing page insights and forms
                        </Heading>

                        <Text mb="8">
                            See how many views your link in the bio page has
                            received and understand the performance of each
                            link.
                        </Text>

                        <Link href="/pricing" passHref>
                            <Button
                                colorScheme="brand"
                                as="a"
                                size={{ base: "md", md: "lg" }}
                            >
                                Learn More
                            </Button>
                        </Link>
                    </GridItem>
                </Grid>

                {/* <Grid
                    templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                    alignItems="center"
                    gap={{ base: "16", lg: "8" }}
                    py="80px"
                >
                    <GridItem>
                        <Heading
                            size="xl"
                            textTransform="capitalize"
                            lineHeight="1.4"
                            mb="5"
                        >
                            get instant notifications of your Landing Page
                            performance
                        </Heading>

                        <Text mb="8">
                            Our Mobile App is specially crafted for you to keep
                            track of your Landing Page in real-time and get
                            notified on your Landing Page updates
                        </Text>

                        <Wrap spacing="3" maxW="500px">
                            <WrapItem flexGrow={1}>
                                <Link
                                    href="https://play.google.com/store/apps/details?id=com.bithub.app"
                                    passHref
                                >
                                    <Button
                                        leftIcon={<AndroidIcon />}
                                        colorScheme="brand"
                                        as="a"
                                        width="100%"
                                        size={{ base: "md", md: "lg" }}
                                    >
                                        Download Now
                                    </Button>
                                </Link>
                            </WrapItem>

                            <WrapItem flexGrow={1}>
                                <Link
                                    href="https://apps.apple.com/us/app/bithub/id1644353385"
                                    passHref
                                >
                                    <Button
                                        width="100%"
                                        leftIcon={<AppleIcon />}
                                        as="a"
                                        size={{ base: "md", md: "lg" }}
                                    >
                                        Download Now
                                    </Button>
                                </Link>
                            </WrapItem>
                        </Wrap>
                    </GridItem>

                    <GridItem>
                        <FakeNotifications />
                    </GridItem>
                </Grid> */}
            </MaxWidthWrapper>
        </Box>
    );
}

export default AboutFeatures;

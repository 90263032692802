import { motion, animate } from "framer-motion";
import React from "react";

interface CounterProps {
    from?: number;
    to: number;
    duration?: number;
}

function Counter(props: CounterProps){
    const from = props.from || 0;
    const isInView = React.useRef(false);

    return (
        <motion.div
            onViewportEnter={entry => {
                if(isInView.current) return;
                isInView.current = true;
                animate(from, props.to, {
                    duration: props.duration || 1,
                    onUpdate: value => {
                        if(entry?.target){
                            entry.target.textContent = String(Math.round(value));
                        }
                    }
                })
            }}
        >
            {props.from || 0}
        </motion.div>
    )
}

export default Counter;